import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'

const AccessoryCarouselImage = ({
  data,
  active,
  width,
  imageOrientation,
}) => {
  const imageData = useMemo(() => {
    switch (imageOrientation) {
      case 'Horizontal':
        return data.image.horizontalImageData
      case 'Vertical':
        return data.image.verticalImageData
      case 'Square':
        return data.image.squareImageData
      default:
        return data.image.horizontalImageData
    }
  }, [imageOrientation, data.image])
  const imageStyle = css`
    width: ${width}px;
    height: 100%;
    display: flex;
    > div {
      display: flex;
    }
    img {
      min-height: 100%;
    }
  `
  return (
    <div css={imageStyle} className={active ? 'active' : ''}>
      <GatsbyImage
        image={imageData}
        alt={data.image.alt || data.accessoryName}
      />
    </div>
  )
}

export default AccessoryCarouselImage
