import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { baseGrid, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import AnimateIn from './AnimateIn'
import ParallaxTranslate from './ParallaxTranslate'

const PhotoIntro = ({ heading, body, bodyNode, image, ...props }) => {
  const sectionStyle = css`
    ${baseGrid};
    grid-template-rows: var(--gutter-xlg) 1fr;
    margin-bottom: var(--gutter-xlg);
    margin-top: calc(var(--gutter-sm) + 1rem);
    ${mq().m} {
      grid-template-rows: auto calc(2 * var(--gutter-xlg)) auto;
    }
    ${mq().s} {
      grid-template-rows: auto var(--gutter-xlg) auto;
    }
  `
  const imageWrapperStyle = css`
    grid-column: 7 / -1;
    grid-row: 1 / 4;
    z-index: 1;
    align-self: flex-start;
    height: 35vw;
    min-height: 100%;
    display: flex;
    ${mq().m} {
      height: 50vw;
      grid-column: 5 / -1;
      grid-row: 1 / 3;
    }
    ${mq().ms} {
      height: 60vw;
      grid-column: 4 / -1;
    }
    ${mq().s} {
      height: 70vw;
      grid-column: 3 / -1;
    }
  `
  const imageStyle = css`
    > div {
      display: flex;
    }
    img {
      height: 100%;
      width: 100%;
    }
  `
  const textboxWrapperStyle = css`
    grid-column: 2 / span 6;
    grid-row: 2 / 3;
    align-self: flex-start;
    z-index: 2;
    /* transform: translateY(calc(-1 * var(--gutter-lg))); */
    margin-bottom: var(--gutter-mlg);
    ${mq().m} {
      --translate-factor: 50;
      height: auto;
      grid-column: 2 / span 9;
      grid-row: 2 / 4;
    }
    ${mq().ms} {
      grid-column: 2 / span 10;
    }
    ${mq().s} {
      --translate-factor: 25;
      grid-column: 2 / span 12;
    }
  `
  const textboxStyle = css`
    background-color: white;
    padding: var(--gutter-sm) var(--gutter-mlg) var(--gutter-md);
    h1 {
      font-size: var(--fs-48);
      margin: 0.5em 0;
    }
    p {
      font-size: var(--fs-21);
      font-weight: 300;
      line-height: 1.75;
    }
  `
  return (
    <section css={sectionStyle} {...props}>
      <AnimateIn wrapperCss={imageWrapperStyle} css={imageStyle}>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt || ''}
          backgroundColor={colors.ecruShade2}
        />
      </AnimateIn>
      <ParallaxTranslate
        css={textboxWrapperStyle}
        innerCss={textboxStyle}
      >
        <h1>{heading}</h1>
        {body && (
          <div
            dangerouslySetInnerHTML={{
              __html: '<p>' + body.replace(/\n/g, '</p><p>') + '</p>',
            }}
          />
        )}
        {bodyNode && (
          <div
            dangerouslySetInnerHTML={{
              __html: bodyNode.childMarkdownRemark.html,
            }}
          />
        )}
      </ParallaxTranslate>
    </section>
  )
}

export default PhotoIntro
