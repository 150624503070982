import { graphql } from 'gatsby'
import { rgba } from 'polished'
import React from 'react'

import AccessoryCarousel from '../components/AccessoryCarousel'
import Layout from '../components/Layout'
import LogoBackground from '../components/LogoBackground'
import PhotoIntro from '../components/PhotoIntro'
import Seo from '../components/Seo'
import { colors } from '../theme/variables'

export const data = graphql`
  query ($slug: String!) {
    page: datoCmsAccessoriesPage(slug: { eq: $slug }) {
      introHeading
      introBody
      introImage {
        gatsbyImageData(imgixParams: { q: 75, ar: "4:3", fit: "crop" })
        alt
        focalPoint {
          x
          y
        }
      }
      accessoryCategories {
        categoryName
        categoryDescription
        imageOrientation
        carouselItems {
          accessoryName
          accessoryDescription
          image {
            horizontalImageData: gatsbyImageData(
              imgixParams: { q: 75, ar: "4:3", fit: "crop" }
            )
            verticalImageData: gatsbyImageData(
              imgixParams: { q: 75, ar: "3:4", fit: "crop" }
            )
            squareImageData: gatsbyImageData(
              imgixParams: { q: 75, ar: "1:1", fit: "crop" }
            )
          }
        }
      }
      seo {
        title
        description
      }
    }
  }
`

const AccessoriesPage = ({ data }) => {
  const { page } = data
  return (
    <Layout>
      <Seo
        title={page.seo?.title || page.introHeading}
        description={page.seo?.description}
      />
      <LogoBackground color={rgba(colors.greenLight, 0.333)} />
      <PhotoIntro
        heading={page.introHeading}
        body={page.introBody}
        image={page.introImage}
      />
      {page.accessoryCategories.map((category, i) => (
        <AccessoryCarousel
          key={i}
          odd={i % 2 === 0 ? true : false}
          categoryName={category.categoryName}
          categoryDescription={category.categoryDescription}
          carouselItems={category.carouselItems}
          imageOrientation={category.imageOrientation}
          css={
            i === page.accessoryCategories.length - 1 && {
              marginBottom: 'var(--gutter-xlg)',
            }
          }
        />
      ))}
    </Layout>
  )
}

export default AccessoriesPage
